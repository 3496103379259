<template>
  <div
    class="
      w-full
      flex flex-col
      items-center
      justify-center
      py-4
      h-screen
      bg-white
    "
  >
    <img src="@/assets/img/error/403.svg" alt="No autorizado" />
    <h1 class="text-2xl font-semibold text-primary-800">Acceso denegado</h1>
    <p class="text-primary-700">
      Lo sentimos, no tienes permisos para acceder a este sitio.
    </p>
    <t-button class="mt-4" @click="redirectHome()">Volver al inicio</t-button>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    redirectHome() {
      window.localStorage.removeItem("vuex")
      window.location.href = "/"
    }
  }
}
</script>
